import { set } from "lodash";
import isEmpty from "lodash.isempty";

export const jsonToQuery = json => {
  if (isEmpty(json)) {
    return "";
  }
  const flatJson = flattenJson(json);

  return (
    "?" +
    Object.keys(flatJson)
      .map(key => {
        return flatJson[key] === 0 || flatJson[key]
          ? encodeURIComponent(key) + "=" + encodeURIComponent(flatJson[key])
          : "";
      })
      .filter(keyVal => keyVal.length)
      .join("&")
  );
};

export const flattenJson = (obj, roots = [], sep = ".") => {
  return Object.keys(obj).reduce(
    (memo, prop) =>
      Object.assign(
        {},
        memo,
        Object.prototype.toString.call(obj[prop]) === "[object Object]"
          ? flattenJson(obj[prop], roots.concat([prop]))
          : { [roots.concat([prop]).join(sep)]: obj[prop] }
      ),
    {}
  );
};

export const searchToObject = (search = "") => {
  if (search) {
    return search
      .slice(1)
      .split("&")
      .map(pair => pair.split("="))
      .reduce(
        (obj, [key, value]) => set(obj, key, decodeURIComponent(value)),
        {}
      );
  }

  return {};
};
