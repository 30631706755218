import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ErrorBoundary = (props) => {
  const [hasError, setHasError] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const { pathname } = location;
    setHasError(false);

    window.addEventListener("unhandledrejection", handlePromiseRejection);
    return () => {
      window.removeEventListener("unhandledrejection", handlePromiseRejection);
    };

    function handlePromiseRejection(event) {
      event.preventDefault();
      setHasError(true);
      navigate(`#${pathname}`);
    }
  }, [location, navigate]);

  if (hasError) {
    return <h1>Something went wrong.</h1>;
  }

  return props.children;
};

export default ErrorBoundary;
