import { lazy } from "react";

export const unauthenticatedRoutes = [
  {
    component: lazy(() => import("./login")),
    path: "/login"
  },
  {
    component: lazy(() => import("./login")),
    path: "/login-2fa"
  },
  {
    component: lazy(() => import("./login/forgot")),
    path: "/forgot"
  }
];

export const authenticatedRoutes = [
  {
    component: lazy(() => import("./activity/detail")),
    path: "/activity/:userId/:type/:activityId"
  },
  {
    component: lazy(() => import("./activity/overview")),
    path: "/activity"
  },
  {
    component: lazy(() => import("./users/details")),
    path: "/users/:id"
  },
  {
    component: lazy(() => import("./users/overview")),
    path: "/users"
  },
  {
    component: lazy(() => import("./device")),
    path: "/users/:userId/devices/:deviceId"
  },
  {
    component: lazy(() => import("./not-found")),
    path: "/404"
  }
];

export const executiveRoutes = [
  {
    component: lazy(() => import("./config/details")),
    path: "/configs"
  },
  {
    component: lazy(() => import("./config/form")),
    path: "/configs/update"
  }
];

export const marketingRoutes = [
  {
    component: lazy(() => import("./brand/details")),
    path: "/brand/:id"
  },
  {
    component: lazy(() => import("./promotion/overview")),
    path: "/promotions"
  },
  {
    component: lazy(() => import("./promotion/form")),
    path: "/promotions/create"
  },
  {
    component: lazy(() => import("./promotion/details")),
    path: "/promotions/:id"
  },
  {
    component: lazy(() => import("./list/overview")),
    path: "/lists"
  },
  {
    component: lazy(() => import("./list/form")),
    path: "/lists/create"
  },
  {
    component: lazy(() => import("./list/details")),
    path: "/lists/:id"
  }
];
