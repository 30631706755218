import { forwardRef, React } from "react";
import { Link } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import { normalize } from "./normalize";
import { breakpoints } from "./breakpoints";
import { fonts } from "./fonts";
import { palette } from "./palette";

const LinkBehavior = forwardRef((props, ref) => {
  const { href, ...rest } = props;
  LinkBehavior.displayName = "LinkBehavior";
  return <Link ref={ref} to={href} {...rest} />;
});

export const theme = createTheme({
  typography: {
    fontFamily: fonts.nunito,
    h1: {
      fontWeight: 700,
      fontSize: 40,
      lineHeight: "117%",
      letterSpacing: "-0.01em",
      [`@media(min-width:${breakpoints.sm}px)`]: {
        fontSize: 48
      }
    },
    h2: {
      fontWeight: 600,
      fontSize: 40,
      lineHeight: "120%",
      letterSpacing: "-0.01em"
    },
    h3: {
      fontWeight: 300,
      fontSize: 36,
      lineHeight: "128%"
    },
    h4: {
      fontWeight: 400,
      fontSize: 32,
      lineHeight: "138%"
    },
    h5: {
      fontWeight: 400,
      fontSize: 28,
      lineHeight: "143%"
    },
    body1: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: "150%"
    }
  },
  breakpoints: {
    values: breakpoints
  },
  palette,
  components: {
    MuiCssBaseline: {
      styleOverrides: normalize
    },
    MuiTypography: {
      defaultProps: {
        variant: "body1",
        variantMapping: {
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          body1: "p"
        }
      }
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior
      }
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior
      }
    }
  }
});
