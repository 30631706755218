import Container from "@mui/material/Container";
import cn from "classnames";
import React from "react";
import { searchToObject } from "../../utilities/jsonQuery";
import s from "./index.module.css";
import { useLocation } from "react-router-dom";

const pageTypes = {
  user: {
    subtitle: "User Not Found",
    message: "The User you're looking for was not found in the database."
  },
  activity: {
    subtitle: "Activity Not Found",
    message: "The Activity you're looking for was not found in the database."
  },
  admin: {
    subtitle: "Restricted Access",
    message: "You do not have access to the Admin pages."
  },
  brand: {
    subtitle: "Restricted Access",
    message: "You do not have access to the Brands pages."
  },
  manualBrands: {
    subtitle: "Restricted Access",
    message: "You do not have access to the Manual Brands page."
  },
  config: {
    subtitle: "User Configs Not Found",
    message:
      "The User configs you're looking for was not found in the database."
  }
};

const NotFound = ({}) => {
  const location = useLocation();

  const { type } = searchToObject(location.search);
  const restricted =
    type === "admin" || type === "brand" || type === "manualBrands";

  let subtitle = "Not Found";
  let message =
    "The page you're looking for does not exist for Raise Admin portal.";
  let subtitleClasses = s.subtitle;

  if (type && pageTypes[type]) {
    subtitle = pageTypes[type].subtitle;
    message = pageTypes[type].message;
    subtitleClasses = cn(s.subtitle, s.smallSubtitle);
  }

  return (
    <div>
      <Container className={s.container}>
        <div className={s.pageContainer}>
          <div className={s.title}>{restricted ? 401 : 404}</div>
          <div className={subtitleClasses}>{subtitle}</div>
          <div className={s.message}>{message}</div>
        </div>
      </Container>
    </div>
  );
};

export default NotFound;
