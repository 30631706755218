import React, { Suspense, useEffect, useState } from "react";
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate
} from "react-router-dom";
import ErrorBoundary from "../components/error-boundary";
import Header from "../components/header";
import {
  checkForCookie,
  checkUnauthenticatedRoute,
  lastInstanceClosed
} from "../utilities/authentication";
import { eraseCookie, setCookie } from "../utilities/cookie";
import "./index.module.css";
import {
  authenticatedRoutes,
  executiveRoutes,
  marketingRoutes,
  unauthenticatedRoutes
} from "./routes";
import NotFound from "./not-found";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { theme } from "../settings";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

const RootComponent = () => {
  const navigate = useNavigate();

  useEffect(() => {
    checkForCookie("auth") && navigate("/users");
  }, [navigate]);

  return <div />;
};

const App = () => {
  const [authenticated, setAuthenticated] = useState(
    checkForCookie("auth") && !lastInstanceClosed()
  );
  const [userRole, setUserRole] = useState(localStorage.getItem("role"));
  const [passwordReset, setPasswordReset] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [userInactive, setUserInactive] = useState(false);
  const [emailSubmitted, setEmailSubmitted] = useState(false);

  const updateLocalStorage = () => {
    const itemsToRemove = ["last-instance-closed", "logout-event", "role"];

    localStorage.setItem("logout-event", "");

    itemsToRemove.forEach((item) => localStorage.removeItem(item));
  };

  const logUserOut = async (unauthorized = false, redirectBack = false) => {
    unauthorized && setTokenExpired(true);
    redirectBack &&
      window.location.hash !== "/" &&
      setCookie("redirectTo", window.location.hash, 60);
    setAuthenticated(false);
    eraseCookie("auth");
    eraseCookie("token");
    updateLocalStorage();
    window.location.replace("/#/login");
  };

  const logOutInactiveUser = () => {
    const twentyMinutes = 60 * 20;
    const oneMinute = 1000 * 60;

    const resetCookie = () => {
      document.cookie = `log-out-inactive="";max-age=${twentyMinutes}`;
    };

    document.addEventListener("mousemove", resetCookie);

    setInterval(() => {
      const isPresentCookie = checkForCookie("log-out-inactive");
      const isUnauthenticatedRoute = checkUnauthenticatedRoute();

      if (!isPresentCookie && !isUnauthenticatedRoute) {
        document.cookie = `log-out-inactive=""`;
        setUserInactive(true);
        logUserOut(false, true);
      }
    }, oneMinute);
  };

  const logOutAllInstances = () => {
    window.addEventListener("storage", (event) => {
      if (event.key === "logout-event") {
        setAuthenticated(false);
      }
    });
    return;
  };

  const increment = (instanceCount) => {
    localStorage.setItem("number-of-instances", `${Number(instanceCount) + 1}`);
  };

  const incrementInstanceCount = () => {
    window.onload = () => {
      const name = window.name;
      const isUnauthenticatedRoute = checkUnauthenticatedRoute();
      const instanceCount = localStorage.getItem("number-of-instances");

      if (name === "loaded") {
        increment(instanceCount);
        return;
      }

      if (instanceCount === "0" && !isUnauthenticatedRoute) {
        localStorage.setItem("number-of-instances", 1);
      } else if (!instanceCount) {
        localStorage.setItem("number-of-instances", 1);
      } else {
        increment(instanceCount);
      }

      window.name = "loaded";
    };
  };

  const decrement = (instanceCount) => {
    localStorage.setItem("number-of-instances", `${Number(instanceCount) - 1}`);
  };

  const decrementInstanceCount = () => {
    window.onunload = () => {
      const finishedLoading = window.name === "loaded";
      let instanceCount = localStorage.getItem("number-of-instances");

      if (instanceCount === "0") {
        return;
      } else if (instanceCount === "1" && finishedLoading) {
        localStorage.setItem("number-of-instances", 0);
      } else if (instanceCount && finishedLoading) {
        decrement(instanceCount);
      }
    };
  };

  const logOutFinalInstance = () => {
    incrementInstanceCount();
    decrementInstanceCount();
  };

  useEffect(() => {
    logOutInactiveUser();
    logOutAllInstances();
    logOutFinalInstance();
  });

  const generateRoutes = (routes) => {
    return routes.map(({ path, component: Component }) => (
      <Route
        exact
        key={path}
        path={path}
        element={
          <Component
            setAuthenticated={setAuthenticated}
            access={{ userRole, setUserRole }}
            reset={{ passwordReset, setPasswordReset }}
            forgot={{ emailSubmitted, setEmailSubmitted }}
            token={{ tokenExpired, setTokenExpired }}
            inactive={{ userInactive, setUserInactive }}
            logUserOut={logUserOut}
          />
        }
      />
    ));
  };

  return (
    <Router>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <ErrorBoundary>
              <Suspense fallback={null}>
                <Header
                  logUserOut={logUserOut}
                  access={{ userRole, setUserRole }}
                />
                <Routes>
                  {generateRoutes(unauthenticatedRoutes)}
                  {!authenticated && window.location.replace("/#/login")}
                  {generateRoutes(authenticatedRoutes)}
                  {generateRoutes(executiveRoutes)}
                  {generateRoutes(marketingRoutes)}
                  <Route path="/" element={<RootComponent />} />
                  <Route path="/404" element={<NotFound />} />
                  <Route path="*" element={<Navigate to="/404" />} />
                </Routes>
              </Suspense>
            </ErrorBoundary>
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </Router>
  );
};

export default App;
