import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { makeStyles } from "@mui/styles";
import Toolbar from "@mui/material/Toolbar";
import React from "react";
import { Link } from "react-router-dom";
import logoImage from "../../static/images/portal-logo.svg";
import { checkUnauthenticatedRoute } from "../../utilities/authentication";
import styles from "./index.module.css";

// To avoid "!important" in the css, as guided by material-ui.com
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  appBar: {
    backgroundColor: "#ffffff",
    height: "70px",
    display: "flex",
    justifyContent: "center",
    boxShadow: "none",
    margin: "20px 0 30px 0"
  },
  link: {
    color: "#000000",
    fontFamily: "Nunito",
    fontSize: "14px",
    transition: "all 0.2s linear",

    "&:hover": {
      textDecoration: "underline",
      color: "#3e3cef"
    }
  },
  button: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  }
}));

const Header = ({ logUserOut, access }) => {
  const { userRole } = access;
  const executiveUser = userRole === "EXECUTIVE";
  const marketingUser = userRole === "MARKETING";
  const isUnauthenticatedRoute = checkUnauthenticatedRoute();
  const classes = useStyles();

  return !isUnauthenticatedRoute ? (
    <div className={styles.header}>
      <div className={classes.root}>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <Container>
              <div className={styles.displayFlex}>
                <>
                  <Link to="/activity" className={classes.link}>
                    <img src={logoImage} alt="logo" className={classes.image} />
                  </Link>
                </>
                <div>
                  {executiveUser && (
                    <Button color="inherit" className={classes.button}>
                      <Link to="/configs" className={classes.link}>
                        Configs
                      </Link>
                    </Button>
                  )}
                  {(executiveUser || marketingUser) && (
                    <Button color="inherit" className={classes.button}>
                      <Link to="/promotions" className={classes.link}>
                        Promotions
                      </Link>
                    </Button>
                  )}
                  {(executiveUser || marketingUser) && (
                    <Button color="inherit" className={classes.button}>
                      <Link to="/lists" className={classes.link}>
                        Lists
                      </Link>
                    </Button>
                  )}
                  <Button color="inherit" className={classes.button}>
                    <Link to="/users" className={classes.link}>
                      Users
                    </Link>
                  </Button>
                  <Button color="inherit" className={classes.button}>
                    <Link to="/activity" className={classes.link}>
                      Activity
                    </Link>
                  </Button>
                  <Button color="inherit" className={classes.button}>
                    <span
                      color="inherit"
                      onClick={() => logUserOut()}
                      className={classes.link}
                    >
                      Log Out
                    </span>
                  </Button>
                </div>
              </div>
            </Container>
          </Toolbar>
        </AppBar>
      </div>
    </div>
  ) : null;
};

export default Header;
