import React from "react";
import { hydrateRoot, createRoot } from "react-dom/client";
import "./index.module.css";
import "./global.module.css";
import App from "./pages/";

const container = document.getElementById("root");

if (container.hasChildNodes()) {
  hydrateRoot(container, <App />);
} else {
  const root = createRoot(container);
  root.render(<App />);
}
