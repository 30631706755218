export const checkForCookie = cookie => {
  const isPresentCookie = ("; " + document.cookie)
    .split(`; ${cookie}=`)
    .pop()
    .split(";")
    .shift();

  return !!isPresentCookie;
};

export const checkUnauthenticatedRoute = customLocation => {
  const unAuthenticatedRoutes = ["#/login", "#/login-2fa", "#/forgot"];
  const isUnauthenticatedRoute = unAuthenticatedRoutes.includes(
    customLocation || window.location.hash
  );

  return isUnauthenticatedRoute;
};

export const lastInstanceClosed = () => {
  const name = window.name;
  const instanceCount = localStorage.getItem("number-of-instances");
  const isUnauthenticatedRoute = checkUnauthenticatedRoute();

  const lastInstanceClosed =
    name !== "loaded" && instanceCount === "0" && !isUnauthenticatedRoute;

  lastInstanceClosed && localStorage.setItem("last-instance-closed", "true");

  return lastInstanceClosed;
};
