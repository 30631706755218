/**
 * @function setCookie
 * @param {string} name - Name of the cookie.
 * @param {string} value - Value to be set for the cookie.
 * @param {number} minutes - Expiry time for the cookie in minutes.
 * @return void
 */
export const setCookie = (name, value, minutes) => {
  let expires = "";
  if (minutes) {
    let date = new Date();
    date.setTime(date.getTime() + minutes * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ""}${expires}; path=/`;
};

/**
 * @function getCookie
 * @param {string} name - Name of the cookie.
 * @return string
 */
export const getCookie = name => {
  const v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? v[2] : "";
};

/**
 * @function getCookie
 * @param {string} name - Name of the cookie.
 * @return void
 */
export const eraseCookie = name => {
  setCookie(name, "", -1);
};
